<template>
  <div >
    <v-container class="my-5">
      <h1 class ="subheading grey--text ml-10">Prigradski saobraćaj</h1>
      <p class="my-3">Domuztrans održava stalne autobuske linije Prnjavor - Kulaši i Prnjavor - Ruževci u  prigradskom saobraćaju, 
        sa sledićim rasporedom vožnje</p> 
        <v-layout row wrap justify-center class="my-10">
          <v-flex xs10 md6>
            <v-card my-5 shaped>
              <v-card-title>
                <h2 class="text-center">Linija Kulaši - Prnjavor</h2>
              </v-card-title>
              <v-card-text   class="         
                 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4"
                           
              >
              <template>
                <v-data-table
                  :headers="headers"
                  :items="linije"
                  hide-default-footer
                  class="elevation-1"
                  
                >
                </v-data-table>
              </template>
                
              </v-card-text>
            </v-card>
        
          </v-flex>
        </v-layout>

        <v-layout row wrap justify-center class="my-10">
          <v-flex xs10 md6>

            <v-card my-5 shaped>
              <v-card-title>
                <h2 class="text-center">Linija Prnjavor - Ruževci</h2>
              </v-card-title>
              <v-card-text>
              <template>
                <v-data-table
                  :headers="headers1"
                  :items="linije1"
                  hide-default-footer
                  class="elevation-1"
                >
                </v-data-table>
              </template>
                
              </v-card-text>
            </v-card>

          </v-flex>
        </v-layout>  
    </v-container>    
  </div>
</template>

<script>
  export default {
    data () {
      return {

        headers: [
          
         
          {
            text: 'br polaska',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Kulaši', sortable: false, value: 'kulasi' },
          { text: 'Prnjavor', sortable: false, value: 'prnjavor' }, 
         
        ],
        linije: [
          {
            name: '1',
            kulasi: '06:00',
            prnjavor: '-----',
           
          },
          
          {
            name: '2',
            kulasi: '08:45',
            prnjavor: '07:00',
           
          },
          
          {
            name: '3',
            kulasi: '11:15',
            prnjavor: '10:30',
           
          },

          {
            name: '4',
            kulasi: '12:26',
            prnjavor: '12:00',
           
          },

          {
            name: '5',
            kulasi: '13:00',
            prnjavor: '12:30',
           
          },

          {
            name: '6',
            kulasi: '14:10',
            prnjavor: '13:20',
           
          },

          {
            name: '7',
            kulasi: '16:00',
            prnjavor: '15:15',
           
          },


          {
            name: '8',
            kulasi: '-----',
            prnjavor: '19:35',
           
          },
          
        ],  


        headers1: [
          {
            text: 'br polaska',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Prnjavor', sortable: false, value: 'prnjavor' },
          { text: 'Ruževci', sortable: false, value: 'ruzevci' },
          
          
        ],
        linije1: [
          {
            name: '1',
            ruzevci: '06:10',
            prnjavor: '-----',
           
          },
          
          {
            name: '2',
            ruzevci: '12:38',
            prnjavor: '12:10',
           
          },
          
          {
            name: '3',
            ruzevci: '-----',
            prnjavor: '13:20',
           
          },

          {
            name: '4',
            ruzevci: '15:43',
            prnjavor: '15:15',
           
          },

          {
            name: '5',
            ruzevci: '17:35',
            prnjavor: '-----',
           
          },

          {
            name: '6',
            ruzevci: '-----',
            prnjavor: '19:40',
           
          },

          
        ],
      }
    },
  }
</script>